<template>
  <div class="user-recharge">
    <h5 class="">充值记录</h5>
    <div class="main mt-3">
      <b-table striped hover head-variant="light" :items="list" :fields="fields"/>
    </div>
  </div>
</template>
<script>
import User from "@/model/User"
export default {
  data() {
    return {
      fields:[
        {key:"created_at", label:"创建时间"},
        {key:"orderSn", label:"流水号"},
        {key:"amount", label:"金额"},
        {key:"balance", label:"余额"},
      ],
      list: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      User.getRecharge().then(response => {
        if(response.data.code === 0) {
          this.list = response.data.data;
        }
      })
    }
  }
}
</script>